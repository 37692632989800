import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Hello, I'm Rylie!</h1>
    <p>Nothing about me is normal... so, you probably have never met anyone quite like me, but I love to celebrate our differences as they really are what makes us all unique. My goal is to be visible and help people understand that despite our differences, we are have much more in common than most people would ever guess.</p>
    <h2>Humble Beginnings</h2>
    <p>I'm just starting to carve out my corner of the Internet, and I'm not a front-end designer, so this is going to be rough for a while.</p>
    <p>For now, you can find me on the following services:</p>
    <ul>
      <li>
        Twitter: <a href="http://twitter.com/darkpanictwitch">@darkpanictwtich</a>
      </li>
      <li>
        Instagram: <a href="http://instagram.com/dark.panic">@dark.panic</a>
      </li>
      <li>
        Twitch: <a href="http://twitch.tv/darkpanic">darkpanic</a>
      </li>
    </ul>
    <p>More to come...</p>


  </Layout>
)

export default IndexPage
